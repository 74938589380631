$body-bg: rgb(255, 255, 255);
$body-color: rgb(27, 27, 27);

$font-family-sans-serif: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande,
  Arial, Ubuntu, Cantarell, Fira Sans, sans-serif;

$border-color: rgb(206, 212, 218);

$primary: #0033a0 ; // 018422
$danger: #0033a0 ;
$light: #f0f0f0;

// Colores corporativos Nutresa
// #57af31 #a5bb3b #1b5731

:root {
  --message-info-backgorund: #d6fffa;
  --message-left-backgorund: #f5f5f5;
  --message-right-backgorund: #ffd1d4;
  --message-info-color: #585858;
  --message-left-color: black;
  --message-right-color: black;
  --tss-light-dark: #bbbbbb;
  --tss-light: #f8f8f8;
}

@import "~bootstrap/scss/bootstrap";

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.tss_container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  min-height: 570px;
  position: relative;
}

@media screen and (min-width: 703px) {
  // 700px user 1441px agent
  .tss_container {
    top: 19px;
    width: 700px; // 700px user 1396px agent
    margin: 0 auto;
    height: calc(100% - 38px);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.tss_header_brand {
  width: 100%;
  z-index: 100;
  height: 60px;
  display: flex;
  padding: 1px 16px;
  position: relative;
  align-items: center;
  box-sizing: border-box;
}

// Chta stye
.tss_chat {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  position: relative;
  flex-direction: column;
}

.tss_chat_header {
  order: 1;
  width: 100%;
  z-index: 100;
  height: 60px;
  display: flex;
  position: relative;
  padding: 10px 16px;
  align-items: center;
  box-sizing: border-box;
}

.tss_chat_body {
  order: 2;
  z-index: 1;
  height: 0px;
  flex: 1 1 0;
  display: flex;
  overflow: auto;
  position: relative;
  flex-direction: column;
}

.tss_chat_footer_container {
  order: 3;
  width: 100%;
  z-index: 100;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;

  .tss_chat_footer {
    width: 100%;
    height: 62px;
    z-index: 100;
    display: flex;
    padding: 10px 16px;
    position: relative;
    align-items: center;
    box-sizing: border-box;

    .form-control {
      font-size: 14px;
      &::-webkit-input-placeholder {
        color: #999999;
      }

      &:-moz-placeholder {
        color: #999999;
      }

      &::-moz-placeholder {
        color: #999999;
      }

      &:-ms-input-placeholder {
        color: #999999;
      }
    }

    .btn-check:focus + .btn,
    .btn:focus {
      box-shadow: none;
    }

    .form-control:focus {
      box-shadow: none;
    }
  }
}

// Actions
.tss_btn_float_menu {
  padding: 0;
  width: 55px;
  right: 17px;
  z-index: 90;
  height: 55px;
  bottom: 85px;
  position: absolute;
  border-radius: 100%;
}

// Message
.tss-chant-container {
  font-size: 15px;

  .tss_chat_body_message_span_left {
    top: 0;
    left: -7px;
    z-index: 100;
    display: block;
    position: absolute;
    border-top-left-radius: 1px;
    border-top: 0px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 7px solid var(--message-left-backgorund);
  }

  .tss_chat_body_message_left {
    width: auto;
    max-width: 90%;
    position: relative;
    border-radius: 7px;
    color: var(--message-left-color);
    border-top-left-radius: 0px !important;
    background-color: var(--message-left-backgorund);
  }

  .tss_chat_body_message_span_right {
    top: 0;
    right: -7px;
    z-index: 100;
    display: block;
    position: absolute;
    border-top-right-radius: 1px;
    border-top: 0px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 7px solid var(--message-right-backgorund);
  }

  .tss_chat_body_message_right {
    width: auto;
    max-width: 90%;
    position: relative;
    border-radius: 7px;
    color: var(--message-right-color);
    border-top-right-radius: 0px !important;
    background-color: var(--message-right-backgorund);
  }

  .tss-chat-info {
    background-color: var(--message-info-backgorund);
    color: var(--message-info-color);
    border-radius: 10px;
    p {
      font-size: 13px;
    }
  }
}

// Emojis content
.emoji-mart-bar {
  border: 0px !important;
}

.emoji-mart-search {
  margin-top: 10px;
  margin-bottom: 10px;

  .emoji-mart-search-icon {
    display: none;
  }

  input {
    border: 0px !important;
    font-size: 13px !important;
    background-color: #e6e6e6;
    padding-top: 10px !important;
    padding-bottom: 10px !important;

    &::-webkit-input-placeholder {
      color: #a5a5a5;
    }

    &:-moz-placeholder {
      color: #a5a5a5;
    }

    &::-moz-placeholder {
      color: #a5a5a5;
    }

    &:-ms-input-placeholder {
      color: #a5a5a5;
    }
  }
}

.emoji-mart-category-label {
  display: none;
}

// @media screen and (min-width: 1025px) and (max-width: 1300px) {
//   .tss_chat_body_message_container {
//     max-width: 75%;
//   }
// }
